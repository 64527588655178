import { EmailOutlined, Person, VpnKey } from "@mui/icons-material";
import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./register.css";
import AuthService from "../../services/auth.services";
import swal from "sweetalert";

function Register() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordAgainRef = useRef();

  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const username = usernameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passwordAgain = passwordAgainRef.current.value;

    if (username.length < 6) {
      swal({
        text: `Username minimal 6 karakter`,
        icon: "error",
        buttons: true,
      });
      return;
    }

    if (password.length < 6) {
      swal({
        text: `Password minimal 6 karakter`,
        icon: "error",
        buttons: true,
      });
      return;
    }

    // if (!password.match(/^([a-z0-9]{6,})$/.test("abc12"))) {
    //   swal({
    //     text: `Password harus terdiri dari angka dan huruf`,
    //     icon: "error",
    //     buttons: true,
    //   });
    //   return;
    // }

    if (password !== passwordAgain) {
      swal({
        text: `Password pertama dan kedua harus sama. Cek password lagi!`,
        icon: "error",
        buttons: true,
      });
      return;
    }

    AuthService.register(username, email, password)
      .then((resp) => {
        // console.log("resp :>> ", resp);
        swal({
          text: `${resp.data.data.email} berhasil didaftarkan!`,
          buttons: true,
          icon: "success",
          timer: 2000,
        });
        history.push("/signin");
      })
      .catch(() => {
        swal({
          text: `Pastikan sudah diisi semua formnya!`,
          buttons: true,
          icon: "error",
          timer: 5000,
        });
      });
    // console.log(usernameRef.current.value, "username");
    // console.log(emailRef.current.value, "email");
    // console.log(passwordRef.current.value, "password");
    // console.log(passwordAgainRef.current.value, "paswordAgain");
  };

  return (
    <div className="wrapperRegister">
      <div className="container-register">
        <div className="iconUser">
          <img src="assets/user.png" alt="User" />
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="inputGroup">
            {/* <label htmlFor="username">Username Address</label> */}
            <div className="inputWrapper">
              <div className="icon">
                <Person />
              </div>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Username"
                ref={usernameRef}
              />
            </div>
          </div>
          <div className="inputGroup">
            {/* <label htmlFor="email">Email Address</label> */}
            <div className="inputWrapper">
              <div className="icon">
                <EmailOutlined />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                ref={emailRef}
              />
            </div>
          </div>
          <div className="inputGroup">
            {/* <label htmlFor="password">Password</label> */}
            <div className="inputWrapper">
              <div className="icon">
                <VpnKey />
              </div>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </div>
          </div>
          <div className="inputGroup">
            {/* <label htmlFor="password">Password</label> */}
            <div className="inputWrapper">
              <div className="icon">
                <VpnKey />
              </div>
              <input
                type="password"
                name="passwordAgain"
                id="passwordAgain"
                placeholder="Password Again"
                ref={passwordAgainRef}
              />
            </div>
          </div>
          <button type="submit">Register</button>
        </form>
        <p>
          Have an account? <Link to="/signin">Login!</Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
