import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ExitToApp, Login } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import AuthService from "../../services/auth.services";
import useStore from "../../store/modalSubs";
import ModalSubscribe from "../../components/Modal/ModalSubscribe";
import "./navbar.css";

function Navbar({ isToken, setIsToken, isLoading }) {
  const currentUser = AuthService.getCurrentUser();
  const history = useHistory();
  const [progress, setProgress] = useState(0); //LINEAR PROGRESS
  const setModal = useStore((state) => state.setIsModal);
  const modal = useStore((state) => state.isModal);

  const logoutHandle = () => {
    AuthService.logout();
    history.replace("/signin");
    setIsToken(false);
  };

  useEffect(() => {
    if (currentUser) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [currentUser, setIsToken]);

  useEffect(() => {
    if (isLoading) {
      setProgress(0);
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 30) {
          return 0;
        }
        return Math.min(oldProgress + 3, 100);
      });
    }, 1350); // Ulangi tarik data per 15 detik
    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  if (modal) {
    return <ModalSubscribe />;
  }

  return (
    <>
      {currentUser && (
        <div className="linear-progress-nav">
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
      <div className="navbar-person">
        <Link to="/">
          <div className="suar-logo">
            <img src="assets/suar.png" alt="logo" width="80px" />
          </div>
        </Link>

        {isToken || currentUser ? (
          <div className="icon-login">
            <Link to="/monitoring">
              <span title="Monitoring">{currentUser.success?.name}</span>
            </Link>
            <span title="Logout">
              <ExitToApp onClick={logoutHandle} fontSize="small" />
            </span>
          </div>
        ) : (
          <>
            <div className="icon-login" title="Login">
              <div className="btn-subscribe">
                <a href="/#" onClick={() => setModal()}>
                  <img src="./assets/btn-subscribe.png" alt="" />
                </a>
              </div>
              <Link to="/signin">
                <span title="login">
                  <Login fontSize="small" />
                </span>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Navbar;
