import React from "react";
// import ReactDatePicker from "react-datepicker";
// import AuthService from "../../services/auth.services";
import "./footer.css";
// import { addDays } from "date-fns";

function Footer({ startDate, setStartDate, endDate, setEndDate }) {
  // const currentUser = AuthService.getCurrentUser();
  //   // DATE PICKER
  //   const [startDate, setStartDate] = useState("");
  //   const [endDate, setEndDate] = useState("");
  //   console.log("endDate di footer :>> ", endDate);

  return (
    <div className="container-footer">
      <div className="wrapper-footer">
        <div className="tradmark">
          {/* <span>MONITOR by tms @2021</span> */}
          <img src="assets/suar-tms-putih.png" alt="logo-suar" width="110px" />
        </div>
        <div className="data-stream">
          {/* {currentUser && currentUser.success.role_id === 3 && (
            <>
              <span>DATA STREAM START: </span>
              <div className="date-picker-input">
                <ReactDatePicker
                  selected={
                    startDate ? startDate : new Date().setHours(0, 0, 0)
                  }
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={addDays(new Date(endDate), -180)}
                  maxDate={new Date()}
                />
              </div>
              <span>-</span>
              <div className="date-picker-input">
                <ReactDatePicker
                  selected={endDate ? endDate : new Date()}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={addDays(new Date(startDate), 1)}
                  maxDate={addDays(new Date(startDate), 180)}
                />
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
