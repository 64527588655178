import { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./app.css";
import Footer from "./components/footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Login from "./pages/Login/Login";
import Maintenance from "./pages/Maintenance/Maintenance";
import Monitoring from "./pages/Monitoring/Monitoring";
import Register from "./pages/Register/Register";
import AuthService from "./services/auth.services";
import instance from "./services/axios";

function App() {
  const currentUser = AuthService.getCurrentUser();
  const [isToken, setIsToken] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  const checkMaintenance = useCallback(async () => {
    try {
      const res = await instance.get("/api/v1/status/maintenance");
      setIsMaintenance(res.data.status === 0);
    } catch (error) {
      console.log(error);
      setIsMaintenance(false);
    }
  }, []);

  useEffect(() => {
    checkMaintenance();
  }, [checkMaintenance]);

  return (
    <>
      <Router>
        {isMaintenance ? (
          <Maintenance />
        ) : (
          <>
            <Navbar
              setIsToken={setIsToken}
              isToken={isToken}
              isLoading={isLoading}
            />
            <Switch>
              <Route path="/signin">
                <Login setIsToken={setIsToken} />
              </Route>
              <Route path="/signup">
                <Register />
              </Route>
              <Route path="/monitoring">
                {currentUser ? (
                  <Monitoring
                    startDate={startDate}
                    endDate={endDate}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ) : (
                  <Login />
                )}
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            <Footer
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </>
        )}
      </Router>
    </>
  );
}

export default App;
