import axios from "axios";

const register = (name, email, password) => {
  return axios.post(process.env.REACT_APP_API_SERVER_URL + "api/register", {
    name,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios.post(process.env.REACT_APP_API_SERVER_URL + "api/login", {
    email,
    password,
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};
export default AuthService;
