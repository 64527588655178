import { EmailOutlined, VpnKey } from "@mui/icons-material";
import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./login.css";
import AuthService from "../../services/auth.services";
import swal from "sweetalert";

function Login({ setIsToken }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    // console.log(emailRef.current.value, "email");
    // console.log(passwordRef.current.value, "password");
    AuthService.login(email, password)
      .then((resp) => {
        localStorage.setItem("user", JSON.stringify(resp.data));

        swal({
          text: `Email ${email} loggin successful`,
          icon: "success",
          timer: 1000,
          buttons: false,
        });

        history.push("/monitoring");

        setIsToken(true);
      })
      .catch((err) =>
        swal({
          text: err.response.data.msg,
          icon: "error",
          timer: 5000,
          buttons: true,
        })
      );
  };

  return (
    <div className="wrapperLogin">
      <div className="container-login">
        <div className="iconUser">
          <img src="assets/user.png" alt="User" />
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="inputGroup">
            <div className="inputWrapper">
              <div className="icon">
                <EmailOutlined />
              </div>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                ref={emailRef}
              />
            </div>
          </div>
          <div className="inputGroup">
            <div className="inputWrapper">
              <div className="icon">
                <VpnKey />
              </div>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </div>
          </div>
          <button type="submit">Login</button>
        </form>
        <p>
          Don't have an account? <Link to="/signup">Register!</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
