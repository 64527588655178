import React from "react";
import "./Maintenance.css";

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <div className="maintenance-suar-logo">
          <img src="./assets/suar-maintenance.png" alt="" height={350} />
        </div>
        <div className="maintenance-description">
          <span>We Are Under Maintenance!</span>
          <span>
            Our website is currently undergoing scheduled maintenance.
          </span>
          <span>We should be back shortly!</span>
          <span>Thank you,</span>
        </div>
        <div className="maintenance-sosmed">
          <img src="./assets/logo-fb.png" alt="" height={40} />
          <img src="./assets/logo-twitter.png" alt="" height={40} />
          <img src="./assets/logo-ig.png" alt="" height={40} />
        </div>
      </div>
      <div className="maintenance-content-image">
        <img src="./assets/server-image.png" alt="" width={400} />
      </div>
    </div>
  );
};

export default Maintenance;
