import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const GenerateTableData = (users, handleActiveInactive, deleteUser) => {
  return users.map((user) => ({
    id: user.id_user_monitor,
    name: user.name,
    email: user.email,
    status: (
      <IOSSwitch
        checked={user.status_user === "Activate"}
        onChange={() => handleActiveInactive(user.id_user_monitor)}
        inputProps={{ "aria-label": "controlled" }}
      />
    ),
    action: (
      <>
        <DeleteIcon
          onClick={() => {
            deleteUser(user.id_user_monitor);
          }}
          style={{ cursor: "pointer" }}
          fontSize="medium"
          color="disabled"
        />
        <SaveIcon
          style={{ cursor: "pointer" }}
          fontSize="medium"
          color="disabled"
        />
      </>
    ),
  }));
};

export default GenerateTableData;
// api key 