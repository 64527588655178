import { Close } from "@mui/icons-material";
import React, { useRef } from "react";
import swal from "sweetalert";
import convertImage from "../../helpers/base64";
import AuthService from "../../services/auth.services";
import useStore from "../../store";
import instance from "../../services/axios";

function Modal() {
  const setModal = useStore((state) => state.setIsModal);

  const namaRef = useRef();
  const titleRef = useRef();
  const gambarRef = useRef();
  const currentUser = AuthService.getCurrentUser();

  // POST CALON IN CARD MODAL
  const handlePostCalon = async (e) => {
    e.preventDefault();

    const nama = namaRef.current.value;
    const title = titleRef.current.value;

    // CONVERT BASE64
    const gambar = URL.createObjectURL(gambarRef.current.files[0]);
    const hasilConvert = await convertImage(gambar);

    const dataReturnPost = await instance.post(
      "api/usermonitor/set",
      {
        name: nama,
        title: title,
        image: hasilConvert,
      },
      {
        headers: { Authorization: `Bearer ${currentUser.success.token}` },
      }
    );
    // Hide Modal
    setModal();

    swal({
      text: dataReturnPost.data?.msg,
      icon: "success",
      timer: 5000,
      buttons: false,
    });
  };

  return (
    <div className="modal-container">
      <div className="container-form">
        <div className="icon-close-modal" onClick={() => setModal()}>
          <Close />
        </div>
        <form onSubmit={handlePostCalon}>
          <label htmlFor="nama-calon">Pencarian</label>
          <input
            type="text"
            id="nama-calon"
            className="input-calon"
            ref={namaRef}
            required
          />
          <label htmlFor="title-calon">Nama Project</label>
          <input
            type="text"
            id="title-calon"
            className="input-calon"
            ref={titleRef}
            required
          />
          <label htmlFor="foto-calon">Gambar Project</label>
          <input
            type="file"
            id="foto-calon"
            className="input-gambar-calon"
            name="foto-calon"
            ref={gambarRef}
            required
          />
          <button type="submit" className="btn-cari">
            Go!
          </button>
        </form>
      </div>
    </div>
  );
}

export default Modal;
