import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/More";
import { CircularProgress } from "@mui/material";
import { addDays } from "date-fns";
// import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./monitoring.css";

import useStore from "../../store";
import useStoreListUser from "../../store/listUser";
import AuthService from "../../services/auth.services";
import DateLocale from "../../helpers/dateLocale";
import CardCalonMember from "../../components/CardCalonMember";
import Modal from "../../components/Modal/Modal";
import instance from "../../services/axios";
import ModalListUser from "../../components/Modal/ModalListUser/ModalListUser";

function Monitoring({ isLoading, setIsLoading }) {
  const currentUser = AuthService.getCurrentUser();
  const detik = 30;
  const [dataMonitoring, setDataMonitoring] = useState([]);
  const [medsos, setMedsos] = useState("twitter");

  // DATE PICKER
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // CONVERT DATE UTC TO LOCALE
  const startDatePlus = startDate && DateLocale(startDate, 7).toISOString();
  const endDatePlus = endDate && DateLocale(endDate, 7).toISOString();

  // CONFIG TANGGAL HARI INI
  const hariIni = new Date();
  const newTahun = hariIni.getFullYear();
  const newBulan = hariIni.getMonth() + 1;
  const newTanggal = hariIni.getDate();
  const newJam = hariIni.getHours();
  const newMenit = hariIni.getMinutes();

  // set store
  const setModal = useStore((state) => state.setIsModal);
  const modal = useStore((state) => state.isModal);
  const setModalListUser = useStoreListUser(
    (state) => state.setIsModalListUser
  );
  const modalListUser = useStoreListUser((state) => state.isModalListUser);
  // GET API MONITORING
  useEffect(() => {
    setIsLoading(true);
    const fetchDataMonitoring = async () => {
      try {
        const dataMonitoring = await instance.post(
          "api/usermonitor/data",
          {
            start_date:
              startDatePlus ||
              `${newTahun}-${newBulan}-${newTanggal}T00:00:00Z`,
            end_date:
              endDatePlus ||
              `${newTahun}-${newBulan}-${newTanggal}T${newJam}:${newMenit}:00Z`,
          },
          {
            headers: { Authorization: `Bearer ${currentUser.success.token}` },
          }
        );

        // SORT
        const dataSort = [...dataMonitoring.data].sort(
          (a, b) => b.profile_id - a.profile_id
        );

        setDataMonitoring(dataSort);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        // swal({
        //   text: error.message,
        //   icon: "error",
        //   timer: 2000,
        //   buttons: false,
        // });
      }
    };

    fetchDataMonitoring();

    // setelah beberapa detik
    const timer = setInterval(() => {
      setIsLoading(true);
      fetchDataMonitoring();
    }, detik * 1000);

    // hapus interval
    return () => {
      clearInterval(timer);
    };
  }, [
    currentUser.success.token,
    newTahun,
    newBulan,
    newJam,
    newMenit,
    newTanggal,
    startDatePlus,
    endDatePlus,
    setIsLoading,
  ]);

  if (modal) {
    return <Modal />;
  }
  if (modalListUser) {
    return <ModalListUser />;
  }

  return (
    <div className="container-person">
      <div className="navbar-stardate">
        {currentUser.success.role_id && (
          <div className="date-picker">
            <div className="date-picker-input">
              <DatePicker
                selected={startDate ? startDate : new Date().setHours(0, 0, 0)}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={addDays(new Date(endDate), -180)}
                maxDate={new Date()}
              />
            </div>
            <span>-</span>
            <div className="date-picker-input">
              <DatePicker
                selected={endDate ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={addDays(new Date(startDate), 1)}
                maxDate={addDays(new Date(startDate), 180)}
              />
            </div>
          </div>
        )}

        <div className="action-monitoring">
          {((currentUser.success.role_id === 1 && dataMonitoring.length < 5) ||
            (currentUser.success.role_id === 2 && dataMonitoring.length < 10) ||
            (currentUser.success.role_id === 3 && dataMonitoring.length < 20) ||
            (currentUser.success.role_id === 4 &&
              dataMonitoring.length >= 0)) && (
            <div className="icon-modal" onClick={() => setModal()}>
              <Add fontSize="large" />
            </div>
          )}
          {currentUser.success.role_id !== 5 &&
            currentUser.success.role_id !== 28 &&
            currentUser.success.role_id !== 29 && (
              <div className="icon-modal" onClick={() => setModalListUser()}>
                <MoreIcon />
              </div>
            )}
        </div>
      </div>

      <div className="wrapper-person">
        {dataMonitoring.length > 0 ? (
          dataMonitoring.map((data, idx) => (
            <CardCalonMember
              key={idx}
              profilId={data?.profile_id}
              isLoading={isLoading}
              setMedsos={setMedsos}
              medsos={medsos}
              dataCalon={data?.user_data}
              image={`data:image/jpeg;base64, ${data?.profile_link}`}
              nama={data?.profile_title}
              title={data?.profile}
            />
          ))
        ) : (
          <div className="loading-circle">
            <CircularProgress size={100} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Monitoring;
