import { Close } from "@mui/icons-material";
import React, { useRef } from "react";
import swal from "sweetalert";
import convertImage from "../../helpers/base64";
import AuthService from "../../services/auth.services";
import useStore from "../../store";
import instance from "../../services/axios";

function ModalEdit() {
  const setModal = useStore((state) => state.setIsModalEdit);
  const modal = useStore((state) => state.isModalEdit);
  // console.log(modal, "modal");

  const namaRef = useRef();
  const titleRef = useRef();
  const gambarRef = useRef();

  const currentUser = AuthService.getCurrentUser();

  // POST CALON IN CARD MODAL
  const handleEdit = async (e) => {
    e.preventDefault();

    const nama = namaRef.current.value;
    const title = titleRef.current.value;

    // CONVERT BASE64
    const gambar =
      gambarRef.current.files[0] &&
      URL.createObjectURL(gambarRef.current.files[0]);
    // console.log(gambar, "gambar");
    const hasilConvert = await convertImage(gambar);
    // console.log(hasilConvert, "hasilConvert");

    const objPesan = {};

    if (gambar === undefined) {
      objPesan.name = nama;
      objPesan.title = title;
    } else {
      objPesan.name = nama;
      objPesan.title = title;
      objPesan.image = hasilConvert;
    }

    const dataReturnPost = await instance.post(
      `api/usermonitor/update/${modal.profilId}`,
      objPesan,
      {
        headers: { Authorization: `Bearer ${currentUser.success.token}` },
      }
    );

    // Modal Hilangkan
    setModal(null);

    swal({
      text: dataReturnPost.data?.msg,
      icon: "success",
      timer: 5000,
      buttons: false,
    });
  };

  return (
    <div className="modal-container">
      <div className="container-form">
        <div className="icon-close-modal" onClick={() => setModal(null)}>
          <Close />
        </div>
        <form onSubmit={handleEdit}>
          <label htmlFor="nama-calon">Pencarian</label>
          <input
            type="text"
            id="nama-calon"
            className="input-calon"
            ref={namaRef}
            required
            defaultValue={modal.title}
          />
          <label htmlFor="title-calon">Nama Project</label>
          <input
            type="text"
            id="title-calon"
            className="input-calon"
            ref={titleRef}
            required
            defaultValue={modal.nama}
          />

          <label htmlFor="foto-calon">Gambar Project</label>
          <div className="title-person">
            <img src={modal.image} alt={modal.title} />
          </div>
          <input
            type="file"
            id="foto-calon"
            className="input-gambar-calon"
            name="foto-calon"
            ref={gambarRef}
          />
          <button type="submit" className="btn-cari">
            Go!
          </button>
        </form>
      </div>
    </div>
  );
}

export default ModalEdit;
