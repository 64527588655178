import create from "zustand";

const useStore = create((set) => ({
  isModal: false,
  setIsModal: () => set((state) => ({ isModal: !state.isModal })),
  isModalEdit: null,
  setIsModalEdit: (person) =>
    set((state) => ({
      isModalEdit: person,
    })),
}));

export default useStore;
