import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import CardCalon from "../components/CardCalon";
// import GoogleTrends from "../helpers/GoogleTrends";
// import { countDensity } from "../helpers/countDensity";
import instance from "../services/axios";

function Home() {
  //  TIME INTERVAL => DETIK
  const detik = 15;
  const [dataDefault, setDataDefault] = useState([]);
  const [medsos, setMedsos] = useState("article");
  const [isLoading, setIsLoading] = useState(false);

  // const [density, setDensity] = useState([]);
  // let dataMedia = "";
  // for (let i = 0; i < dataDefault[0]?.user_data[0]?.data.length; i++) {
  //   dataMedia += dataDefault[0]?.user_data[0]?.data[i].content;
  // }
  // console.log(dataMedia, "dataMedia");
  // console.log(countDensity(dataMedia), "content");
  // setDensity(countDensity(dataMedia));
  // console.log(dataPrabowo[3], "dataPrabowo[3]");
  // CONFIG TANGGAL
  // const hariIni = new Date();
  // const newTahun = hariIni.getFullYear();
  // const newBulan = hariIni.getMonth() + 1;
  // const newTanggal = hariIni.getDate();

  // GET API DEFAULT
  useEffect(() => {
    setIsLoading(true);
    const fetchDataDefault = async () => {
      const dataDefault = await instance.get("api/dmonitor");
      setDataDefault(dataDefault.data);
      setIsLoading(false);

      // Density Word
      // let dataMedia = "";
      // for (let i = 0; i < dataDefault.data[0]?.user_data[0]?.data.length; i++) {
      //   dataMedia += dataDefault.data[0]?.user_data[0]?.data[i].content;
      // }
      // setDensity(countDensity(dataMedia));
    };

    fetchDataDefault();

    // setelah beberapa detik
    const timer = setInterval(() => {
      setIsLoading(true);
      fetchDataDefault();
    }, detik * 1000);

    // hapus interval
    return () => {
      clearInterval(timer);
    };
  }, [setIsLoading]);

  return (
    <>
      <div className="container-person">
        {/* <div className="navbar-stardate">
        <h3>
          {`Data Stream Start:
          ${newTahun}-${newBulan}-${newTanggal} 00:00:01`}
        </h3>
      </div> */}
        <div className="wrapper-person">
          {dataDefault.length > 0 ? (
            dataDefault.map((data, idx) => (
              <CardCalon
                key={idx}
                isLoading={isLoading}
                setMedsos={setMedsos}
                medsos={medsos}
                dataCalon={data?.user_data}
                image={`data:image/jpeg;base64, ${data?.profile_link}`}
                nama={data?.profile_title}
              />
            ))
          ) : (
            <div className="loading-circle">
              <CircularProgress size={100} />
            </div>
          )}
        </div>
        {/* Google Trends */}
        {/* <div id="widget">
        <div className="wrapper-name" style={{ textAlign: "center" }}>
          <h4>GOOGLE TRENDS</h4>
        </div>
        <GoogleTrends
          type="dailytrends"
          url="https://ssl.gstatic.com/trends_nrtr/2674_RC03/embed_loader.js"
        />
      </div> */}
        {/* {density.length > 0 && (
        <div id="widget">
          <div className="wrapper-name" style={{ textAlign: "center" }}>
            <h4>Twitter - Prabowo</h4>
          </div>
          {density.map((item, idx) => (
            <div className="density-wrapper" key={idx}>
              <div className="title-density">{item[0]}</div>
              <div className="count-density">{item[1]}</div>
            </div>
          ))}
        </div>
      )} */}
      </div>
    </>
  );
}

export default Home;
